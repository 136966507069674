import { AfterViewInit, ChangeDetectionStrategy, Component, viewChild, input, inject } from '@angular/core';
import {NgComponentOutlet} from "@angular/common";
import {IModalButton, IModalData, ModalService} from "../../services/modal.service";

@Component({
  selector: 'st-modal',
  imports: [
    NgComponentOutlet
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements AfterViewInit {
  private modal = inject(ModalService);

  readonly ngComponentOutlet = viewChild(NgComponentOutlet);
  readonly data = input<IModalData | null>(null);

  ngAfterViewInit() {
    const ngComponentOutlet = this.ngComponentOutlet();
    if (ngComponentOutlet && ngComponentOutlet['_componentRef']?.instance) {
      ngComponentOutlet['_componentRef'].instance._modal = this;
    }
  }

  onButtonClick(btn: IModalButton, e: MouseEvent) {
    if (btn.click) {
      btn.click(btn, e);
    }
    const data = this.data();
    if (btn.close && data) {
      this.modal.close(data);
    }
  }

  close() {
    const data = this.data();
    if (!data) {
      return;
    }
    this.modal.close(data);
  }
}
