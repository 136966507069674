import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {StartupService} from "./services/startup.service";
import {httpInterceptor} from "./services/http-interceptor";
import {provideClientHydration} from "@angular/platform-browser";
import {provideAnimations} from "@angular/platform-browser/animations";
import {STORAGE_TOKEN} from '../oex-ui-kit/services/storage.service';
import {StorageService} from './services/storage.service';
import {DatePipe} from '@angular/common';

export const initializeApplication = async () => {
  await inject(StartupService).initialize();
};

/*const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'disabled',
  scrollPositionRestoration: 'disabled'
};*/

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe,
    provideHttpClient(withInterceptors([httpInterceptor]), withFetch()),
    provideClientHydration(),
    provideAnimations(),
    provideRouter(
      routes,
      // withInMemoryScrolling(scrollConfig)
      // withDebugTracing()
      // withPreloading(PreloadAllModules)
    ),
    provideAppInitializer(initializeApplication),
    {provide: STORAGE_TOKEN, useClass: StorageService}
  ]
};
