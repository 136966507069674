import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {NgOptimizedImage} from "@angular/common";
import {DialogService} from "../../services/dialog.service";
import {AuthService} from "../../services/auth.service";
import {ModalService} from "../../services/modal.service";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'st-home-page',
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit {
  auth = inject(AuthService);
  private api = inject(APIService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private modal = inject(ModalService);
  private ds = inject(DialogService);
  private cdr = inject(ChangeDetectorRef);
  private readonly onLogout$ = this.auth.onLogout.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.onLogout$.subscribe(() => {
      this.cdr.detectChanges();
    });
    if (this.route.snapshot.queryParamMap.get('form') === '1') {
      this.onApplyClick();
    }
  }

  /* test() {

     if (localStorage.getItem('test') === '1') {
       console.log('HOME: auth');
       void this.api.doOAuthLogin('F4571018-CB93-11EE-BAB0-2605FA821D90');
     }
     console.log('HOME: state');
     void this.api.getState();
   }*/

  onApplyClick() {
    if (!this.auth.isLogged) {
      this.ds.showUnAuthDialog(location.href + '?form=1');
      return;
    }

    if (this.auth.user?.startup?.slug) {
      // void this.router.navigateByUrl(`/portal/edit/${this.auth.user?.startup?.slug}`);
      void this.router.navigateByUrl(`/portal`);
      return;
    }

    void this.modal.show({
      component: import('./../sign-up-page/sign-up-page.component')
    });
  }
}
